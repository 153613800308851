<mat-toolbar color="primary">
  <img class="avatar" height="40" width="40" [ngSrc]="player()?.photoURL ?? 'assets/loading/red.svg'" alt="Profil billede">{{ player()?.displayName ?? 'Velkommen' }}
</mat-toolbar>
<mat-action-list style="padding: 1rem 1.5rem 0 1.5rem">
  @if (seasonId()) {
    <sha-sidenav-button *shaHasRole="'player'" [icon]="icon.fasHome" [routerLink]="seasonId()" (click)="closing.emit()">Forside</sha-sidenav-button>
    <sha-sidenav-button *shaHasRole="'player'" [icon]="icon.fasFlagCheckered" [routerLink]="[seasonId(), 'race']" (click)="closing.emit()">Løb</sha-sidenav-button>
    <sha-sidenav-button *shaHasRole="'player'" [icon]="icon.farTrophy" [routerLink]="[seasonId(), 'wbc']" (click)="closing.emit()">WBC</sha-sidenav-button>
    <sha-sidenav-button [icon]="icon.fasPeopleGroup" [routerLink]="[seasonId(), 'teams']" (click)="closing.emit()">Hold</sha-sidenav-button>
    <sha-sidenav-button *shaHasRole="'player'" [icon]="icon.fasSteeringWheel" [routerLink]="[seasonId(), 'standings']" (click)="closing.emit()">Kører</sha-sidenav-button>
  }
  <mat-divider></mat-divider>
  <sha-sidenav-button *shaHasRole="'player'" [icon]="icon.fasCoins" [routerLink]="['accounts', 'me']" (click)="closing.emit()">Konto - {{ player()?.balance }}kr.
  </sha-sidenav-button>
  <sha-sidenav-button *shaHasRole="'bank-admin'" [icon]="icon.farPiggyBank" routerLink="accounts" (click)="closing.emit()">Bank</sha-sidenav-button>
  <sha-sidenav-button *shaHasRole="'admin'" [icon]="icon.fasUserAstronaut" [routerLink]="['players']" (click)="closing.emit()">Spillere</sha-sidenav-button>
  <mat-divider></mat-divider>
  <sha-sidenav-button [icon]="icon.farUserFriends" [routerLink]="['info', 'about']" (click)="closing.emit()">Om spillet</sha-sidenav-button>
  <sha-sidenav-button [icon]="icon.farBookAlt" [routerLink]="['info', 'rules']" (click)="closing.emit()">Regler</sha-sidenav-button>
  <sha-sidenav-button *shaHasRole="'player'" [icon]="icon.farUser" routerLink="player" (click)="closing.emit()">Profil</sha-sidenav-button>
  <sha-sidenav-button [icon]="icon.farInfo" [routerLink]="['info', 'privacy-policy']" (click)="closing.emit()">Persondatapolitik</sha-sidenav-button>
  @if (player()) {
    <sha-sidenav-button [icon]="icon.farSignOutAlt" [routerLink]="['logout']" (click)="closing.emit()">Log ud</sha-sidenav-button>
  } @else {
    <button mat-list-item (click)="signIn(); closing.emit()">
      <fa-icon [icon]="icon.farSignInAlt" [fixedWidth]="true"></fa-icon>
      Log ind
    </button>
  }
</mat-action-list>
